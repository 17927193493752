import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import BottomBar from '../components/BottomBar';
import Products from '../components/Products';
import { Helmet } from 'react-helmet';
import 'normalize.css';
import Layout from '../components/Layout';

export const query = graphql`
  query($slug: String!) {
    ogImg: contentfulOpenGraph {
      ogimage {
        file {
          url
          fileName
        }
      }
    }
    contentfulProduct(slug: { eq: $slug }) {
      slug
      subtitle
      title
      name
      heroImage {
        file {
          url
        }
      }
      productIcons {
        file {
          url
        }
        description
        title
      }
    }
  }
`;

const ProductPage = props => {
  const bannerImg = props.data.ogImg.ogimage[4].file.url;
  return (
    <React.Fragment>
      <SEO
        title='Products'
        description={props.data.contentfulProduct.title}
        bannerImage={bannerImg}
        pathname='Products'
      />
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
        />
      </Helmet>
      <Layout>
        <Hero
          title={props.data.contentfulProduct.title}
          subtitle={props.data.contentfulProduct.subtitle}
          bgImage={props.data.contentfulProduct.heroImage.file.url}
          isProducts={true}
          isHomePage={true}
          isFull='is-fullheight-with-navbar'
        />
        <Products data={props.data.contentfulProduct.productIcons} />
        <BottomBar />
      </Layout>
    </React.Fragment>
  );
};

export default ProductPage;
